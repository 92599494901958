import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseEntityService, CrudEndpoints } from "@gci/components/base-page/base-entity.service";
import { BaseEntityFilter } from "@gci/components/base-page/base-parent-child.service";
import { Role } from "app/models/role.type";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

// Define interfaces for type safety
interface RoleFilter extends BaseEntityFilter {
  // Add any specific filter properties for roles if needed
}


@Injectable({ providedIn: 'root' })
export class RoleService extends BaseEntityService<Role> {

    constructor(protected override httpClient: HttpClient) {
        const endpoints: CrudEndpoints = {
            list: 'default/role', 
        };

        super(httpClient, environment.apiBaseUrl, endpoints, 'RoleService');
    }

    /**
     * Get roles as an observable
     */
    get roles$(): Observable<Role[]> {
        return this._allItems.asObservable();
    }

    /**
     * Set roles and update entities
     */
    set roles(data: Role[]) {
        this._allItems.next(data);
    }

    /**
     * Get list of roles
     * @param forceRefresh Force refresh the cache
     * @returns Observable of Role array
     */
    getRoles(forceRefresh = false): Observable<Role[]> {
        return this.getList(forceRefresh);
        // return this.getList('default', undefined, forceRefresh).pipe(
        //     tap(roles => {
        //         // Update the entities
        //         this.roles = roles;
        //     })
        // );
    }
}