import { Component, OnDestroy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Logger } from '@gci/helpers/logger';
import { WorkerService } from '@gci/helpers/worker.service';
import {
    OnPageVisible, OnPageHidden,
    OnPageVisibilityChange,
    AngularPageVisibilityStateEnum,
    OnPagePrerender, OnPageUnloaded} from 'angular-page-visibility';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { OnlineStatusComponent } from "../@gci/components/online-status/online-status.component";

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        RouterOutlet,
        OnlineStatusComponent
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnDestroy {
    private _title = 'gci-fuse';
    private _logger = new Logger('AppComponent');
    private _subscription = new Subscription();

    onlineEvent: Observable<Event> | undefined;
    offlineEvent: Observable<Event> | undefined;

    connectionStatusMessage: string = '';
    connectionStatus: string = '';

    // Getter
    get title(): string {
        return this._title;
    }

    /**
     * Constructor
     */
    constructor(
        private _serviceWorker: WorkerService, 
        // private _websocketService: WebsocketService,
    )
    {
      
        const sub = this._serviceWorker.$isAnyNewUpdateAvailable.subscribe((versionAvailableFlag: boolean) => {
            this._logger.debug("app.component versionAvailable", versionAvailableFlag);
        });    
        this._subscription.add(sub);

        this.onlineEvent = fromEvent(window, 'online');
        this.offlineEvent = fromEvent(window, 'offline');
    
        this._subscription.add(this.onlineEvent.subscribe(e => {
          this.connectionStatusMessage = 'Back to online';
          this.connectionStatus = 'online';
        }));

        this._subscription.add(this.offlineEvent.subscribe(e => {
            this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
            this.connectionStatus = 'offline';
            console.log('Offline...');
          }));
    }
    
    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    @OnPageVisible()
    logWhenPageVisible (): void {
        this._logger.debug( 'OnPageVisible => visible' );
    
        // this._websocketService.onPageVisible();
        this._serviceWorker.checkNewVersionUpdate();
    }

    @OnPageHidden()
    logWhenPageHidden (): void {
        this._logger.debug( 'OnPageHidden => hidden' );
        // this._websocketService.onPageHidden();
    }

    @OnPagePrerender()
    logWhenPagePrerender (): void {
        this._logger.debug( 'OnPagePrerender => prerender' );
    }

    @OnPageUnloaded()
    logWhenPageUnloaded (): void {
        this._logger.debug( 'OnPageUnloaded => unloaded' );
    }

    @OnPageVisibilityChange()
    logWhenPageVisibilityChange ( visibilityState: AngularPageVisibilityStateEnum ): void {
        if ( AngularPageVisibilityStateEnum[visibilityState]
        === AngularPageVisibilityStateEnum[AngularPageVisibilityStateEnum.VISIBLE]) {
            this._logger.debug( 'OnPageVisibilityChange => visible' );
        } 
        else if (AngularPageVisibilityStateEnum[visibilityState]
        === AngularPageVisibilityStateEnum[AngularPageVisibilityStateEnum.HIDDEN]) {
            this._logger.debug( 'OnPageVisibilityChange => hidden' );
        } 
        else if (AngularPageVisibilityStateEnum[visibilityState]
        === AngularPageVisibilityStateEnum[AngularPageVisibilityStateEnum.PRERENDER]) {
            this._logger.debug( 'OnPageVisibilityChange => prerender' );
        } 
        else if (AngularPageVisibilityStateEnum[visibilityState]
        === AngularPageVisibilityStateEnum[AngularPageVisibilityStateEnum.UNLOADED]) {
            this._logger.debug( 'OnPageVisibilityChange => unloaded' );
        }
    }
}
