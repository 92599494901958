import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-online-status',
    template: `
    <div class="h-screen w-screen fixed left-0 top-0 bg-black flex items-center justify-center text-3xl z-999 overflow-hidden" *ngIf="openWarning">
      <div class="text-white bg-gradient-to-tr text-center p-10 rounded-xl" [class]="onlineStatus === 'online' ? 'from-green-600 to-green-500' : 'from-red-600 to-red-500'">
        <mat-icon *ngIf="onlineStatus === 'online'" class="text-white icon-size-20">wifi</mat-icon>
        <mat-icon *ngIf="onlineStatus === 'offline'" class="text-white icon-size-20">wifi_off</mat-icon>
        <div class="status-message">{{ onlineStatusMessage }}</div>
      </div>
    </div>
  `,
    standalone: true,
    imports: [CommonModule, MatIconModule]
})
export class OnlineStatusComponent implements OnInit, OnChanges {
    @Input() onlineStatusMessage: string = '';
    @Input() onlineStatus: string = '';

    openWarning: boolean = false

    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {
        if ('onlineStatus' in changes && this.onlineStatusMessage !== '') {
            this.openWarning = true;
            if (this.onlineStatus === 'online') {
                setTimeout(() => {
                    this.openWarning = false;
                    this.onlineStatusMessage = '';
                }, 2000);
            }

        }
    }

    ngOnInit() { }
}
