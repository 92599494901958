<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [appearance]="navigationAppearance"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="IsSuperAdmin ? navigation.superadmin : navigation.compact"
    [opened]="!isScreenSmall"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex h-32 items-center justify-center">
            <img class="w-48" src="images/logo/logo.png" alt="Logo image" />
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex w-full min-w-0 flex-auto flex-col max-w-screen overflow-x-hidden">
    <!-- Header -->
    <div
        class="bg-card relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none md:px-6 print:hidden"
    >
        <div class="flex items-center space-x-2 pr-2">
            <!-- Navigation toggle button -->
            <button
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')"
            >
                <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
            </button>
            <!-- Navigation appearance toggle button -->
            <button
                class="hidden md:inline-flex"
                mat-icon-button
                (click)="toggleNavigationAppearance()"
            >
                <mat-icon
                    [svgIcon]="'heroicons_outline:arrows-right-left'"
                ></mat-icon>
            </button>

            <div *ngIf="TenantName && !IsSuperAdmin" class="hidden sm:block select-none opacity-20 text-left">
                <h1 class="text-2xl font-semibold ">{{ TenantName }}</h1>
                <p class="-mt-1">{{TenantDesc}}</p>
            </div>
        </div>
        <!-- Components -->
        <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
            <languages></languages>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <search [appearance]="'bar'" *ngIf="!IsSuperAdmin"></search>
            <shortcuts *ngIf="!IsSuperAdmin"></shortcuts>
            <!-- <messages></messages> -->
            <notifications *ngIf="!IsSuperAdmin"></notifications>
            <!-- <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()"
            >
                <mat-icon
                    [svgIcon]="'heroicons_outline:chat-bubble-left-right'"
                ></mat-icon>
            </button> -->
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-auto flex-col p-5 items-center w-full max-w-screen">
        <app-version-update></app-version-update>
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        @if (true) {
            <div class="w-full max-w-screen-xl">
                <router-outlet></router-outlet>
            </div>
        }
    </div>

    <!-- Footer -->
    <!-- <div
        class="bg-card relative z-49 flex h-14 w-full flex-0 items-center justify-start border-t px-4 dark:bg-transparent md:px-6 print:hidden"
    >
        <span class="text-secondary font-medium"
            >Gapura Cerdas Indonesia &copy; {{ currentYear }}</span
        >
    </div> -->
    <app-footer-vertical></app-footer-vertical>
</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->
