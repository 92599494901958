import { Injectable } from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';
import { DateTime } from 'luxon';
// import * as moment from 'moment';

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  /**
   * Constructor
   */
  constructor() {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the equivalent "IsActiveMatchOptions" options for "exact = true".
   */
  get exactMatchOptions(): IsActiveMatchOptions {
    return {
      paths: "exact",
      fragment: "ignored",
      matrixParams: "ignored",
      queryParams: "exact",
    };
  }

  /**
   * Get the equivalent "IsActiveMatchOptions" options for "exact = false".
   */
  get subsetMatchOptions(): IsActiveMatchOptions {
    return {
      paths: "subset",
      fragment: "ignored",
      matrixParams: "ignored",
      queryParams: "subset",
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Generates a random id
   *
   * @param length
   */
  randomId(length: number = 10): string {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let name = "";

    for (let i = 0; i < 10; i++) {
      name += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return name;
  }

  $formatNumber(number: any, lang?: string, decimal?: number): string {
    if (number === null) {
      return "0";
    }
    let [integerPart, decimalPart] = number.toString().split(".");
    if (decimalPart && decimal !== undefined && decimalPart.length > decimal) {
      [integerPart, decimalPart] = number.toFixed(decimal).split(".");
      decimalPart = decimalPart?.replace(/0+$/, "");
    }

    !lang && (lang = "en");
    integerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      lang === "id" ? "." : ","
    );

    return decimalPart
      ? `${integerPart}${lang === "id" ? "," : "."}${decimalPart}`
      : integerPart;
  }

  // $formatDate(
  //   activeLang: any,
  //   time: any,
  //   format: string,
  //   type?: string
  // ): string {
  //   // let format = this._translateService.translate('MMMM DD, YYYY')
  //   if (type === "week") {
  //     return moment(time).locale(activeLang).subtract(6, "days").format(format);
  //   } else if (type === "monthstart") {
  //     return moment(time).locale(activeLang).startOf("month").format(format);
  //   } else if (type === "monthend") {
  //     return moment(time).locale(activeLang).endOf("month").format(format);
  //   } else if (type === "utc") {
  //     return moment.utc(time).local().locale(activeLang).format(format);
  //   }
  //   return moment(time).locale(activeLang).format(format);
  // }

  $formatDate(
    activeLang: string,
    time: any,
    format: string,
    type?: string
  ): string {
    const date = DateTime.fromISO(time).setLocale(activeLang);
    
    if (type === "week") {
      return date.minus({ days: 6 }).toFormat(format);
    } else if (type === "monthstart") {
      return date.startOf("month").toFormat(format);
    } else if (type === "monthend") {
      return date.endOf("month").toFormat(format);
    } else if (type === "utc") {
      return DateTime.fromISO(time, { zone: 'utc' }).setLocale(activeLang).toLocal().toFormat(format);
    }
  
    return date.toFormat(format);
  }
  

  $convertDuration(seconds: number): string {
    const hours: number = Math.floor(seconds / 3600);
    const remainingSeconds: number = seconds % 3600;
    const minutes: number = Math.floor(remainingSeconds / 60);

    const result: string = `${hours === 0 ? "" : hours} ${
      hours === 0 ? "" : "Hours"
    } ${minutes} Minutes`;
    return result;
  }

  $statusCheck(number: any): string {
    switch (number) {
      case 2:
        return "Charging";
      case 1:
        return "Breaker On";
      case 0:
        return "Breaker Off";
      default:
        return "Offline";
    }
  }

  $getDecimalSeparator(value: number | string) {
    if (value) {
      const strValue = value.toString();

      let indexColon = strValue.indexOf(",");
      let indexPeriod = strValue.indexOf(".");

      if (indexColon !== -1 && indexPeriod !== -1) {
        if (indexColon > indexPeriod) {
          return ",";
        } else {
          return ".";
        }
      }

      if (indexColon !== -1) {
        const arr = strValue.split(",");
        if (arr[arr.length - 1].length !== 3) {
          return ",";
        }
      }
      if (indexPeriod !== -1) {
        const arr = strValue.split(".");
        if (arr[arr.length - 1].length !== 3) {
          return ".";
        }
      }
    }
    return "";
  }

  $BuildQueryString(params?: Record<string, string>): string {
    if (!params) return '';
    const queryParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      if (value) queryParams.append(key, value);
    });
    return queryParams.toString() ? `?${queryParams.toString()}` : '';
  }
}
