import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, tap, throwError, of } from 'rxjs';
import { Logger } from '@gci/helpers/logger';
import { PaginationMetaData } from 'app/models/event.type';
import { IResponse, PaginatedResponse } from './base.type';

declare global {
    interface ErrorConstructor {
        captureStackTrace(targetObject: object, constructorOpt?: Function): void;
    }
}

export class ServiceError extends Error {
    constructor(
        message: string,
        public operation: string,
        public endpoint: string,
        public originalError: any
    ) {
        super(message);
        this.name = 'ServiceError';
        // Maintains proper stack trace for where error was thrown
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ServiceError);
        }
    }
}

export interface BaseEntity {
    id: string;
    [key: string]: any;
}

export interface CrudEndpoints {
    list?: string;
    create?: string;
    update?: (id: string) => string;
    delete?: (id: string) => string;
    [key: string]: any;
}

export type EntityTransformFn<T> = (response: T) => T;


@Injectable()
export abstract class BaseEntityService<T extends BaseEntity> {
    protected logger: Logger;
    protected baseUrl: string;
    protected endpoints: CrudEndpoints;
    protected cacheDuration: number;

    // State management
    protected _allItems: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);

    protected _allItemsNewFormat: BehaviorSubject<IResponse<T> | null> = new BehaviorSubject<IResponse<T> | null>(null);

    protected _pagination: BehaviorSubject<PaginationMetaData| null> = new BehaviorSubject<PaginationMetaData | null>(null);
    protected _activeItem: BehaviorSubject<T | null> = new BehaviorSubject<T | null>(null);
    protected _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private lastFetchTime: number = 0;

    constructor(
        protected httpClient: HttpClient,
        baseUrl: string,
        endpoints: CrudEndpoints,
        serviceName: string,
        cacheDuration: number = 5 * 60 * 1000,
    ) {
        this.baseUrl = baseUrl;
        this.endpoints = endpoints;
        this.cacheDuration = cacheDuration;
        this.logger = new Logger(serviceName);
    }

    // Generic accessors
    get allItems$(): Observable<T[]> {
        return this._allItems.asObservable();
    }

    get allItemsNewFormat$(): Observable<IResponse<T> | null> {
        return this._allItemsNewFormat.asObservable();
    }

    set allItems(data: T[]) {
        this._allItems.next(data);
    }

    get activeItem$(): Observable<T | null> {
        return this._activeItem.asObservable();
    }

    get activeItemId(): string {
        return this._activeItem.getValue()?.id || '';
    }

    get loading$(): Observable<boolean> {
        return this._loading.asObservable();
    }

    set activeItem(id: string) {
        const allItems = this._allItems.getValue();
        const foundItem = allItems.find(item => item.id === id);
        this._activeItem.next(foundItem || null);
    }

    private isCacheValid(): boolean {
        return Date.now() - this.lastFetchTime < this.cacheDuration;
    }

    // CRUD Operations
    getList(forceRefresh = false, queryParams?: Record<string, string>): Observable<T[]> {
        // Use cached data if within duration and not forced refresh
        if (!forceRefresh && this.isCacheValid()) {
            return of(this._allItems.getValue());
        }

        const url = `${this.baseUrl}${this.endpoints.list}${this.buildQueryString(queryParams)}`;

        this._loading.next(true);
        return this.httpClient.get<{ message: T[] }>(url).pipe(
            map(response => response.message),
            tap(items => {
                this._allItems.next(items);
                this.lastFetchTime = Date.now();
                if (items.length && !this._activeItem.getValue()) {
                    this._activeItem.next(items[0]);
                }
                this._loading.next(false);
            }),
            catchError(error => {
                this._loading.next(false);
                return this.handleOperationError('getList', this.endpoints.list, error);
            })
        );
    }
    // {status: string,  data: T[], pageination: PaginationMetaData}

    // CRUD Operations
    getListNewFormat(forceRefresh = false, queryParams?: Record<string, string>): Observable<T[]> {
        // Use cached data if within duration and not forced refresh
        // if (!forceRefresh && this.isCacheValid()) {
        //     return of({status: '400', status_message: 'success',  data: this._allItems.getValue(), pagination: this._pagination.getValue()})
        //     // return of(this._allItems.getValue());
        // }

        const url = `${this.baseUrl}${this.endpoints.list}${this.buildQueryString(queryParams)}`;
        this._loading.next(true);
        return this.httpClient.get<PaginatedResponse<T>>(url).pipe(
            map(response => {
                this._allItemsNewFormat.next(response.message);
                return response.message.data
            }),
            tap(items => {
                this._allItems.next(items);
                this.lastFetchTime = Date.now();
                if (items.length && !this._activeItem.getValue()) {
                    this._activeItem.next(items[0]);
                }
                this._loading.next(false);
            }),
            catchError(error => {
                this._loading.next(false);
                return this.handleOperationError('getList', this.endpoints.list, error);
            })
        );
    }

    search(queryParams?: Record<string, string>): Observable<T[]> {
        const url = `${this.baseUrl}${this.endpoints.list}${this.buildQueryString(queryParams)}`;
        this._loading.next(true);
        return this.httpClient.get<PaginatedResponse<T>>(url).pipe(
            map(response => {
                return response.message.data
            }),
            tap(items => {
                return items
            }),
            catchError(error => {
                this._loading.next(false);
                return this.handleOperationError('getList', this.endpoints.list, error);
            })
        );
    }

    create(
        data: Partial<T>,
        transformFn?: EntityTransformFn<T>
    ): Observable<T> {
        this._loading.next(true);
        return this.httpClient.post<{ message: T }>(`${this.baseUrl}${this.endpoints.create}`, data).pipe(
            map(response => response.message),
            tap(newItem => {
                const currentItems = this._allItems.getValue();
                const newItemTransformed = transformFn
                    ? transformFn(newItem)
                    : newItem;
                this._allItems.next([...currentItems, newItemTransformed]);
                this._activeItem.next(newItemTransformed);
                this._loading.next(false);
            }),
            catchError(error => {
                this._loading.next(false);
                return this.handleOperationError('create', this.endpoints.create, error);
            })
        );
    }

    createNewFormat(
        data: Partial<T>,
        transformFn?: EntityTransformFn<T>
    ): Observable<T> {
        this._loading.next(true);
        return this.httpClient.post<{ message: {data: T} }>(`${this.baseUrl}${this.endpoints.create}`, data).pipe(
            map(response => response.message.data),
            tap(newItem => {
                const currentItems = this._allItems.getValue();
                if (currentItems) {
                    const newItemTransformed = transformFn
                    ? transformFn(newItem)
                    : newItem;
                    this._allItems.next([...currentItems, newItemTransformed]);
                    this._activeItem.next(newItemTransformed);
                }
                this._loading.next(false);
            }),
            catchError(error => {
                this._loading.next(false);
                return this.handleOperationError('create', this.endpoints.create, error);
            })
        );
    }

    update(
        id: string,
        data: Partial<T>,
        transformFn?: EntityTransformFn<T>
    ): Observable<T> {
        this._loading.next(true);
        const endpoint = this.endpoints.update ? this.endpoints.update(id) : '';
        return this.httpClient.put<{ message: T }>(`${this.baseUrl}${endpoint}`, data).pipe(
            map(response => response.message),
            tap(updatedItem => {
                const currentItems = this._allItems.getValue();
                const updatedItemTransformed = transformFn
                    ? transformFn(updatedItem)
                    : updatedItem;
                const updatedItems = currentItems.map(item =>
                    item.id === updatedItem.id ? { ...item, ...updatedItemTransformed } : item
                );

                this._allItems.next(updatedItems);
                if (this._activeItem.getValue()?.id === updatedItem.id) {
                    this._activeItem.next(updatedItemTransformed);
                }
                this._loading.next(false);
            }),
            catchError(error => {
                this._loading.next(false);
                return this.handleOperationError('update', endpoint, error);
            })
        );
    }

    delete(id: string): Observable<T> {
        this._loading.next(true);
        const endpoint = this.endpoints.delete ? this.endpoints.delete(id) : '';
        return this.httpClient.delete<{ message: T }>(`${this.baseUrl}${endpoint}`).pipe(
            map(response => response.message),
            tap(deletedItem => {
                const currentItems = this._allItems.getValue();
                const filteredItems = currentItems.filter(item => item.id !== deletedItem.id);
                this._allItems.next(filteredItems);

                if (this._activeItem.getValue()?.id === deletedItem.id) {
                    this._activeItem.next(filteredItems[0] || null);
                }
                this._loading.next(false);
            }),
            catchError(error => {
                this._loading.next(false);
                return this.handleOperationError('delete', endpoint, error);
            })
        );
    }

    // Enhanced error handling
    protected handleOperationError(operation: string, endpoint: string = '', error: HttpErrorResponse): Observable<never> {
        const errorMessage = error.error?.message || error.message || 'An error occurred';

        this.logger.error(`${operation} failed:`, {
            operation,
            endpoint,
            error: error.error,
            status: error.status,
            statusText: error.statusText
        });

        return throwError(() => new ServiceError(
            errorMessage,
            operation,
            endpoint,
            error
        ));
    }

    protected buildQueryString(params?: Record<string, string>): string {
        if (!params) return '';
        const queryParams = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
            if (value) queryParams.append(key, value);
        });
        return queryParams.toString() ? `?${queryParams.toString()}` : '';
    }

    // State management
    clearState(): void {
        this._allItems.next([]);
        this._activeItem.next(null);
        this._loading.next(false);
        this.lastFetchTime = 0;
    }
}