import { HttpClient, HttpContext, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, firstValueFrom, map, Observable, ReplaySubject, take, tap, throwError } from 'rxjs';
import { User } from './user.types';
import { UserApiService } from 'app/web-api/common/user-api.service';
import { environment } from 'environments/environment';
import { Logger } from '@gci/helpers/logger';
import { SKIP_AUTH_INTERCEPTOR } from '../auth/auth.interceptor';

@Injectable({ providedIn: 'root' })
export class UserService {

    // -----------------------------------------------------------------------------------------------------
    // @ Properties
    // -----------------------------------------------------------------------------------------------------
    private _baseUrl = environment.apiBaseUrl;
    private _userApiService = inject(UserApiService);
    private _http = inject(HttpClient);
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    private _currentUser: User | null = null;
    private _logger = new Logger('UserService');


    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Please if you want to update the user, use the setter method instead of directly using the .next() method
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._currentUser = value;
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    /**
     * Access the current user data directly here
     */
    private get currentUser$(): User | null {
        return this._currentUser;
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current signed-in user data
     */
    get(): Observable<User> {
        return this._userApiService.fetchCurrentUserData().pipe(
            map((userApiObject) => {
                this._logger.log("User Api Object", userApiObject);
                if (!userApiObject) {
                    return {} as User;
                }
                
                const user: User = {...userApiObject};

                this._logger.log("Get User", user);

                // this._user.next(user);
                this.user = user;
                return user;
            })
        )
    }

    /**
     * Update the user's language
     *
     * @param newLanguage
     */
    updateUserLanguage(newLanguage: string) {
        if (this._currentUser) {
            this._logger.log("UpdateUserLanguage", newLanguage);
            this._currentUser.language = newLanguage;
        }
    }

    /**
     * Update the user's status
     *
     * @param user
     */
    update(newUserInfo: User): Observable<any> {
        this._logger.log("Update User", newUserInfo);
        return this._http.put<{message: User}>(`${this._baseUrl}acc/user-mgmt/user`, newUserInfo).pipe(
            map((response) => {
                const updatedUser: User = {...this.currentUser$, ...response.message};
                this._logger.log("Update Response", updatedUser);
                // this._user.next(updatedUser);

                this.user = updatedUser;

                return updatedUser;
            }),
            catchError(err => {
                this._logger.error('Error occurred: ', err);
                return throwError(() => new Error(err.error.message));
            })
        );
    }

    /**
     * Get the url for profile avatar image upload
     * @param user 
     * @param fileType 
     * @returns 
     */
    getAvatarImageUploadUrl(user: User, fileType: string): Observable<any> {
        // Create parameter for http request
        const params = new HttpParams()
            .set('object', 'user')
            .set('format', fileType)
            .set('id', user.id);

        this._logger.log("UploadURL Params", params);

        return this._http.get<{message: string}>(`${this._baseUrl}file-mgmt/upload`, {params}).pipe(
            map(res => res.message),
            tap({
                next: (response) => {
                    this._logger.debug(response);
                },
                error: (error) => {
                    this._logger.error('uploadAvatar error', error);
                }
            })
        )
    }

    /**
     * Get the url for profile avatar image download
     * 
     * Also use this url to update the user avatar when updating image
     * @param user 
     * @param fileType 
     * @returns 
     */
    getAvatarImageDownloadUrl(user: User, fileType: string): Observable<any> {
        const params = new HttpParams()
            .set('object', 'user')
            .set('format', fileType)
            .set('id', user.id);

        return this._http.get<{message: string}>(`${this._baseUrl}file-mgmt/download`, {params}).pipe(
            map(res => res.message),
            catchError(err => {
                this._logger.error("Error occurred: ", err);
                return throwError(() => new Error(err.error.message));
            })
        )
    }

    /**
     * Delete the avatar image of the user
     * @param user 
     * @returns 
     */
    deleteAvatarImage(user: User): Observable<string> {
        // Create parameter for http request
        const params = new HttpParams()
            .set('object', 'user')
            .set('id', user.id)

        return this._http.delete<{message: any}>(`${this._baseUrl}file-mgmt/delete`, {params}).pipe(
            map(res => res.message),
            catchError(err => {
                this._logger.error("Error occurred: ", err);
                return throwError(() => new Error(err.error.message));
            })
        )
    }

    /**
     * Upload the image selected to update user's profile image
     * @param uploadUrl 
     * @param selectedFile 
     * @returns 
     */
    updateImage(uploadUrl: string, selectedFile: File): Observable<any> {
        // Create headers without Authorization
        const headers = new HttpHeaders({
            'Content-Type': selectedFile.type,
        });

        // Use HttpClient with custom configuration to skip the auth interceptor
        return this._http.put(
            uploadUrl,
            selectedFile,
            {
                headers,
                reportProgress: true,
                observe: 'events',
                // Skip the auth interceptor
                context: new HttpContext().set(SKIP_AUTH_INTERCEPTOR, true)
            }
        ).pipe(
            catchError(err => {
                this._logger.error(err);
                return throwError(() => new Error(err));
            })
        );
    }
}
