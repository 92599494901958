<div class="fixed z-[999] grid gap-1 sm:gap-2 p-1 sm:p-3 max-w-screen-sm w-screen sm:w-fit" [ngClass]="getPosition()">
    <div *ngFor="let info of toastInfo" class="rounded-md overflow-hidden w-full light-shadow-card" [class]="getColor(info.type)" 
        [@fadeInLeft]="toastInfo.length === 1 && position.includes('l')" 
        [@fadeInBottom]="toastInfo.length > 1 || (!position.includes('l') && !position.includes('r'))" 
        [@fadeInRight]="toastInfo.length === 1 && position.includes('r')"
        [@fadeOutBottom]="true"
        (mouseenter)="onHover(info)" 
        (mouseleave)="onLeave(info)">
        <!--Content-->
        <div class="flex justify-between items-center w-full p-2 px-4">
            <div class="max-w-screen-sm w-full sm:w-[300px]" >
                {{info.msg | transloco}} 
                <!-- {{info.activeTime}} -->
            </div>
            <button mat-icon-button (click)="cancelToast(info)" class="p-0 flex justify-center items-center w-7 h-7">
                <mat-icon class="text-white">cancel</mat-icon>
            </button>
        </div>
        <!--Progress bar-->
        <div class="rounded-full bottom-0 left-0 h-1 transition-all" [class]="getColorLoadingBar(info.type)"
            [ngStyle]="{ 'width': getProgressWidth(info) }"></div>

    </div>
</div>


