import { NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import {
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { BaseComponent } from '@gci/components/base-page/base-component';
import { LangDefinition, TranslocoService } from '@jsverse/transloco';
import { UserService } from 'app/core/user/user.service';
import { FLAG_CODES } from 'app/models/language.constants';
import { take } from 'rxjs';

@Component({
    selector: 'languages',
    templateUrl: './languages.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'languages',
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, NgTemplateOutlet],
})
export class LanguagesComponent extends BaseComponent implements OnInit, OnDestroy {
    availableLangs: LangDefinition[] = [];
    activeLang!: string;
    flagCodes: any;
    typeof: any;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _translocoService: TranslocoService,
        private _userService: UserService
    ) {
        super('LanguagesComponent', _translocoService);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    override ngOnInit(): void {   
        // Get the available languages from transloco
        const availableLangs = this._translocoService.getAvailableLangs();
        if (Array.isArray(availableLangs) && availableLangs.every(lang => typeof lang === 'object')) {
            this.availableLangs.push(...availableLangs as LangDefinition[]);
        }
        
        // Set the country iso codes for languages for flags
        this.flagCodes = FLAG_CODES;

        super.ngOnInit();
    }

    override onLanguageChanged(lang: string): void {
        // Get the active lang
        this.activeLang = lang;
        // Update the navigation
        this._updateNavigation(lang);
        this._changeDetectorRef.detectChanges();        
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the active lang
     *
     * @param lang
     */
    setActiveLang(lang: string): void {
        this.logger.debug('Setting active language to:', lang);
        // Set the active lang
        this._translocoService.setActiveLang(lang);
        this._userService.updateUserLanguage(lang);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the navigation
     *
     * @param lang
     * @private
     */
    private _updateNavigation(lang: string): void {
        // For the demonstration purposes, we will only update the Dashboard names
        // from the navigation but you can do a full swap and change the entire
        // navigation data.
        //
        // You can import the data from a file or request it from your backend,
        // it's up to you.

        // Get the component -> navigation data -> item
        const navComponent =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                'mainNavigation'
            );

        // Return if the navigation component does not exist
        if (!navComponent) {
            return;
        }

        // Get the flat navigation data
        const navigation = navComponent.navigation;

        const navItems: {[index: string]: string} = {
            'main': 'Main',
            'overview': 'Overview',
            'devices': 'Devices',
            'alertrules': 'Alert Rules',
            'members': 'Members',
            'doct': 'Documentation',
            'exit': 'Sign out',
            'signout': 'Sign out'
        };

        const subtitleItems: {[index: string]: string} = {
        };

        // translate all navigation items
        Object.keys(navItems).forEach((key) => {
            const value = navItems[key];
            const item = this._fuseNavigationService.getItem(key, navigation);

            if ( item ) {
                this._translocoService.selectTranslate(value).pipe(take(1))
                    .subscribe((translation) => {
                        item.title = translation;
                        navComponent.refresh();
                    });
                const sub = subtitleItems[key];
                if (sub) {
                    this._translocoService.selectTranslate(sub).pipe(take(1))
                        .subscribe((translation) => {
                            item.subtitle = translation;
                            navComponent.refresh();
                        });
                }
            }
        });
    }
}
